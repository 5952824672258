//function replaces äåÅÄööé etc.
export const normalizeString = (str: string) => {
    return str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
};


/**
 * [FUNCTION] - New file to base 64 string
 * * Will need to remove older function later!!!
 * @param file 
 * @returns 
 */
export const convertFileToBase64String = (file: File): Promise<{ fileName: string; base64String: string }> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result as string;
            const base64DataIndex = base64String.indexOf("base64,");
            if (base64DataIndex === -1) {
                reject(new Error("Invalid Base64 data format"));
                return;
            }
            const base64Data = base64String.slice(base64DataIndex + 7);
            const fileName = normalizeString(file.name);
            resolve({ fileName, base64String: base64Data });
        };
        reader.onerror = () => reject(new Error("File reading error"));
    });



/**
 * @deprecated - Use "import { convertFileToBase64String } from 'Utils/imageUtils';" 
 */
export { convertFileToBase64String as fileToBase64String };